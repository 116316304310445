<template>
  <v-fade-transition>
    <v-btn
      aria-label="up"
      v-scroll="onScroll"
      v-show="showFooter"
      fab
      dark
      fixed
      bottom
      right
      color="secondary"
      transition="slide-y-transition"
      @click="toTop"
    >
      <v-icon :aria-hidden="!showFooter" v-text="'$top'" />
    </v-btn>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'upButton',
  computed: {
    ...mapState('shared', [
      'showFooter',
    ]),
  },
  methods: {
    ...mapActions({
      toggleFooter: 'shared/toggleFooter',
    }),
    onScroll() {
      this.toggleFooter(window.scrollY > window.innerHeight / 4);
    },
    toTop() {
      this.$vuetify.goTo(0, { duration: 300 });
    },
  },
};
</script>

<style scoped>
</style>
